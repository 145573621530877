<template>
    <div class="home_about">
        <div class="home_max_w">
            <el-carousel :autoplay='false' arrow="always" class="swiper_wrap">
              <el-carousel-item v-for="item in aboutList" :key="item.title">
                 <div class="card_item flex_box">
                    <div class="card_left">
                        <div class="about_img"><img :src="item.imgUrl" alt="item.title"/></div>
                        <div class="about_title">{{item.title}}</div>
                        <div class="about_cont">{{item.cont}}</div>
                        <router-link :to="item.linkTo" class="btn_about_more">了解更多</router-link>
                    </div>
                    <div class="card_right">
                      <img :src="item.videoLink" >
                      <div class="video_bg flex_center"><i class="iconfont icon-24gf-playCircle"></i></div>
                    </div>
                </div>
              </el-carousel-item>
            </el-carousel>

        </div>
    </div>
</template>
<script>

export default {
    name:'about',
    data() {
      return {
          aboutList:[{
              imgUrl:require('@/assets/img/about_suppliers.png'),
              title:'供应商',
              cont:'新冠病毒疫情的爆发，使我们的生产停滞，随着国内疫情逐步恢复后，我们又一度面临了资金压力，是平台帮助了我们。通过平台，不断解决了流动资金的问题，优化了我们的信用，是的我们与客户的关系更加稳固，同时平台也为我们推荐了更多优质客户。',
              linkTo:'#',
              videoLink:require('@/assets/img/home_about/about1.png'),
         },
         {
            imgUrl:require('@/assets/img/about_core_enterpris.png'),
            title:'核心企业',
            cont:'通过平台的供应链管理，帮助我们的供应商解决了信用扩容问题，缓解了资金压力，提升了我们的竞争优势。通过平台减缓了本公司应付账款以及流动资金的压力，并且获得了更多优质的供应商。',
            linkTo:'#',
            videoLink:require('@/assets/img/home_about/about2.png'),
         },
         {
            imgUrl:require('@/assets/img/about_management.png'),
            title:'资方',
            cont:'通过平台提供的一站式的信控管理，贷后管理信息得到彻底的穿透，对贷后资金安全带来了极大的帮助。通过平台带来了大量的优质客户，使我们投资的灵活性、自主性、安全性得到极大的提升，对平台留下了深刻的印象。',
            linkTo:'#',
            videoLink:require('@/assets/img/home_about/about3.png'),
         }
         ]
      }
    },
    methods:{

    },
  
}
</script>
<style scoped>
.home_about{
    width: 100%;
    background: #F7FAFC;
}
.home_max_w{
    max-width: 1900px;
    margin: auto;
    overflow: hidden;
}
.el-carousel--horizontal{
    width: 67.6%;
    height: 450px;
    margin: 120px auto 140px;
    overflow-x: initial;
    background-color: #F7FAFC;
}
.swiper_wrap /deep/ .el-carousel__container{
      height: 450px;
}
.el-carousel__item{
    width: 100%;
    color: #162747;
    font-size: 14px;
    margin: 0;
    padding: 10px 80px;
  }
  .el-carousel__item::after{
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    background-color: rgba(247, 250, 252, .79);
  }
  .el-carousel__item.is-active.is-animating::after{
    background-color: transparent;
  }
  .el-carousel__item:nth-child(2n) {
    background-color:  #F7FAFC;
  }
  
  .el-carousel__item:nth-child(2n+1) {
    background-color: rgba(247, 250, 252, .79);
  }
  .swiper_wrap /deep/ .el-carousel__indicators{
      display: none !important;
  }
  .swiper_wrap /deep/ .el-carousel__arrow--left{
        margin-left: -112px;
  }
    .swiper_wrap /deep/ .el-carousel__arrow--right{
        margin-right: -112px;
  }
  .swiper_wrap /deep/ .el-carousel__arrow,
   .swiper_wrap /deep/ .el-carousel__arrow:hover {
    background: #FFFFFF;
    box-shadow: 0px 8px 14px 0px rgba(11, 94, 94, 0.24);
}
.swiper_wrap /deep/ .el-carousel__arrow,
.swiper_wrap /deep/ .el-carousel__arrow i{
  width: 56px;
  height: 56px;
  line-height: 56px;
  font-size: 34px;
  font-style: inherit;
  font-family: "iconfont" !important;
}
.swiper_wrap /deep/ .el-icon-arrow-right:before {
    content: "\e766";
}
.swiper_wrap /deep/ .el-icon-arrow-left:before {
    content: "\ec0f";
}
 .swiper_wrap /deep/ .el-carousel__arrow,
 .swiper_wrap /deep/ .el-carousel__arrow:hover i{
   color: #339C9B;
 }
  .card_left{
      width: 30.625%;
      height: 430px;
  }
  .about_img{
      margin-bottom: 20px;
  }
  .about_img img{
    height: 24px;
  }
  .about_title{
      font-size: 40px;
      color: #162747;
      margin-bottom: 50px;
  }
  .about_cont{
    height: 150px;
    font-size: 14px;
    color: #54657F;
    line-height: 30px;
    margin-bottom: 70px;
  }
  .btn_about_more{
      display: block;
      width: 160px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #339C9B;
      border-radius: 20px;
      font-size: 16px;
      color: #FFFFFF;
  }
  .card_right{
    position: relative;
      width: 730px;
      height: 430px;
      margin-left: 50px;
      background: #339C9B;
  }
  .video_bg{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .46);
    color: #FFFFFF;
  }
  .video_bg i{
    font-size: 70px;
   }
    .swiper_wrap >>>.el-carousel__arrow--right:hover{
     cursor:pointer;
   }
   .swiper_wrap >>> .el-carousel__arrow--right{
     cursor:pointer;
   }
   .swiper_wrap >>> .el-carousel__arrow--right i {
    cursor: pointer !important;
}
@media screen and (max-width: 1664px) {
 .el-carousel--horizontal{
   height: 297px;
   margin: 79px auto 92px;
 }
 .swiper_wrap >>> .el-carousel__container{
   height: 297px;
 }
 .swiper_wrap >>> .el-carousel__arrow {
    top: 45% !important;
}
.swiper_wrap >>> .el-carousel__arrow--left{
  margin-left: -85px;
}
 .swiper_wrap >>> .el-carousel__arrow--right{
    margin-right: -74px;
 }
.swiper_wrap /deep/ .el-carousel__arrow,
.swiper_wrap /deep/ .el-carousel__arrow i{
  width: 37px;
  height: 37px;
  line-height: 37px;
  font-size: 22px;
}
 .el-carousel__item{
    font-size: 12px;
    padding: 7px 53px;
 }
 .card_left{
   height: 284px;
 }
 .card_right {
    width: 482px;
    height: 284px;
    margin-left: 33px;
}
.about_title {
    font-size: 26px;
    margin-bottom: 33px;
}
.about_cont {
    height: 99px;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 46px;
}
.about_img {
    margin-bottom: 13px;
}
.about_img img{
    height: 16px;
}
.btn_about_more{
  width: 106px;
  height: 26px;
  line-height: 26px;
  border-radius: 13px;
  font-size: 12px;
}
}
</style>