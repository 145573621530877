<template>
  <div>
    <div class="home_center home_log flex_center_start_box">
      <div class="home_center_item">
        <div>
          <div class="home_center_title text_center">供应商</div>
          <p class="home_center_cont text_center">向企业供应各种所需资源的企业和个人</p>
        </div>
      </div>
      <div class="home_center_item">
        <div>
          <div class="home_center_title text_center">核心企业</div>
          <p class="home_center_cont text_center">金融机构向整个供应链提供金融服务</p>
        </div>
      </div>
      <div class="home_center_item">
        <div>
          <div class="home_center_title text_center">资 方</div>
          <p class="home_center_cont text_center">私营工商业中的资本家一方</p>
        </div>
      </div>
      <div class="home_center_item no_after">
        <router-link to="/secure/serviceProvider">
          <div class="home_center_title text_center">服务商</div>
          <p class="home_center_cont text_center">提供全面、可靠、专业的信贷全流程服务</p>
        </router-link>
      </div>
      <div class="home_center_btn m_auto">
        <button class="btn_login" @click="loginDialog()">登 录</button>
      </div>
    </div>

    <!-- 登录表单 -->
    <div class="login_wrap" v-show="choseRole">
      <el-dialog title="请选择角色" :visible.sync="choseRole" center :close-on-click-modal='false'>
        <div class="radio_user">
          <el-radio-group v-model="roleId">
            <el-radio-button v-for="(item,index) in userList" :key='index' :label="item.roleId">{{item.name}}</el-radio-button>
          </el-radio-group>
          <div class="btn_box_next">
            <el-button class="btn_next" @click="next()">下一步</el-button>
          </div>
          <div class="register reg_m_b">没有账号？<span @click="linkRegister()">立即注册</span></div>
        </div>
      </el-dialog>
    </div>
    <div class="login_wrap" v-show="popLoginForm">
      <el-dialog :visible.sync="popLoginForm" center :close-on-click-modal='false' custom-class="pop_login">
        <el-tabs v-model="loginType" @tab-click="changeLoginType" class="login_tabs flex_center_col">
          <el-tab-pane label="账号登录" name="accountLogin">
            <el-form :model="loginForm" :rules="loginRules" ref="loginForm" class="accunt_form">
              <div class="err_bg" v-show="errFlag"><i class="iconfont icon-jinggao" /> &nbsp;<span>{{errMsg}}</span></div>
              <el-form-item prop="username">
                <el-input v-model="loginForm.username" placeholder="请输入账号" @focus='errFlag=false' @keyup.enter.native="submitAccountForm('loginForm')"></el-input>
              </el-form-item>
              <el-form-item prop="password" class="password_inpt">
                <el-input v-model.trim="loginForm.password" :type="type" placeholder="请输入密码" @focus='errFlag=false' @keyup.enter.native="submitAccountForm('loginForm')">
                  <i slot="suffix" class="iconfont icon-yanjing" @click="inputType = !inputType"></i>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-button class="btn_form_prev" @click="preStep()">上一步</el-button>
                <el-button class="btn_form_log" @click="submitAccountForm('loginForm')">登&nbsp;&nbsp;录</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>

          <el-tab-pane label="短信登录" name="mobileLogin">
            <el-form :model="loginPhoneForm" :rules="loginRules" ref="loginPhoneForm" class="accunt_form">
              <div class="err_bg1"> &nbsp;<span>短信登录暂无法使用</span></div>
              <el-form-item prop="phoneNum">
                <el-input v-model="loginPhoneForm.phoneNum" placeholder="请输入手机号码" maxlength="16"></el-input>
              </el-form-item>
              <el-form-item prop="code" class="send_code_group">
                <el-input v-model="loginPhoneForm.code" class="send_code" id="code" placeholder="请输入验证码"></el-input>
                <el-button class="btn btn_send_code" @click="sendCode" v-show="setTimeFlag">发送验证码</el-button>
                <div class="setTime" v-show="!setTimeFlag">{{setTime}}s</div>
              </el-form-item>
              <el-form-item>
                <el-button class="btn_form_prev" @click="preStep()">上一步</el-button>
                <el-button class="btn_form_log" @click="submitPhoneForm('loginPhoneForm')">登&nbsp;&nbsp;录</el-button>
                <!-- <el-button class="btn_form_login"  @click="submitPhoneForm('loginPhoneForm')"></el-button> -->
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <div class="register">没有账号？<span @click="linkRegister()">立即注册</span></div>
        </el-tabs>

      </el-dialog>
    </div>
  </div>
</template>
<script>
import { doLogin, loginOut } from "@/api/api.js";
export default {
  name: 'login',
  data() {
    return {
      inputType: false,//查看密码
      popLoginForm: false,//this.$route.params.popLoginForm,
      errFlag: false,     //后台错误提示
      errMsg: '登录账户或密码错误',  //后台报错信息
      setTimeFlag: true,  //发送倒计时显示
      setTime: '',
      timer: null,
      loginForm: { //用户账户
        username: '',
        password: '',
      },
      loginPhoneForm: {//用户手机
        phoneNum: '',
        code: ''
      },
      loginType: 'accountLogin',
      //登录校验
      loginRules: {
        username: [
          { required: true, message: '请输入账号', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        phoneNum: [
          { required: true, message: '请输入手机号码', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      },
      roleId: '1',
      choseRole: this.$route.params.choseRole,//false,
      //nextChoseRole:false,
      userList: [{ roleId: '1', name: "供应商" }, { roleId: '3', name: "核心企业" }, { roleId: '2', name: "资 方" }, { roleId: '4', name: "服务商" }],
    }
  },
  computed: {
    type() {
      return this.inputType ? "text" : "password";
    },
  },
  methods: {
    //切换tab
    changeLoginType(tab, event) {
      // console.log(tab, event);
    },

    // 登陆账号校验
    submitAccountForm(formName) {
      this.errFlag = false
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.errFlag = false
          this.login()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //登出
    async logOut() {
      localStorage.clear()
      let res = await loginOut()
      // if(res&&res.code==200){
      //   this.login()
      // }
    },
    //弹出登录框
    loginDialog() {
      this.choseRole = true
    },
    // 登陆
    async login() {

      let localCookie = localStorage.getItem('localCookie');
      if (localCookie != "") {
        await loginOut()
      }
      let res = await doLogin('username=' + this.loginForm.username + '&password=' + encodeURIComponent(this.loginForm.password) + "&roleIds=" + this.roleId)
      if (res && res.code == 200) {
        localStorage.clear()

        localStorage.setItem('localCookie', this.$cookies.get('JSESSIONID'));

        let obj = res.data
        // this.roleId=='2'?obj.manageRoleId='2':(this.roleId=='1'?obj.supplyRoleId='1':obj.enterRoleId='3')
        let userInfo = { userId: obj.id, roleId: obj.roleId, }
        localStorage.setItem('userDate', JSON.stringify(obj));
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        localStorage.setItem('cnCompany', obj.cnCompany);
        if (obj.roleId == '1') {
          this.$router.push({ path: '/system/supplier/workbench' })
        }
        else if (obj.roleId == '2') {
          this.$router.push({ path: '/system/investor/workbench' })
        }
        else if (obj.roleId == '3') {
          this.$router.push({ path: '/system/coreEnterprise/workbench' })
        } else if (obj.roleId == '4') {
          this.$router.push({ path: 'system/service/workbench' })
        }
      } else {
        this.errFlag = true
        this.errMsg = res.msg
        return false
      }
    },

    //账号校验 获取手机号
    checkaccount() {
      if (!this.timer) {
        document.querySelector('#code').focus();
        const time = 60
        this.setTime = time;
        this.setTimeFlag = false;
        this.timer = setInterval(() => {
          if (this.setTime > 1 && this.setTime <= time) {
            this.setTime--;
          } else {
            this.setTimeFlag = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000)

      }
    },

    // 发送验证码
    sendCode() {
      this.errFlag = false
      if (this.loginPhoneForm.phoneNum == "") {
        return
      }
      this.checkaccount()
    },
    //链接到注册页
    async linkRegister() {
      await loginOut().then(() => {
        this.$router.push({ path: '/secure/register' })
      })
    },

    //选择角色
    next() {
      if (this.roleId) {
        this.choseRole = false
        this.popLoginForm = true

        this.$nextTick(() => {
          this.$refs.loginForm.resetFields()
          this.errFlag = false
          this.errMsg = ''
        })
      }
    },

    //上一步
    preStep() {
      this.choseRole = true
      this.popLoginForm = false
      this.$refs.loginForm.resetFields()
      this.errFlag = false
      this.errMsg = ''
      this.loginType = 'accountLogin'
    },
  }
}
</script>
<style scoped>
  .home_log {
    width: 1600px;
    margin: 120px auto 0;
  }
  .home_center_item {
    position: relative;
    width: 331px;
    color: #162747;
  }
  .home_center_item:after {
    content: '';
    position: absolute;
    right: 0;
    top: 8px;
    width: 1px;
    height: 50px;
    background-color: #ddd;
  }
  .no_after::after {
    content: '';
    background-color: #fff;
  }
  .home_center_title {
    font-size: 22px;
    margin-bottom: 20px;
    color: #162747;
  }
  .home_center_cont {
    font-size: 16px;
    color: #54657f;
  }
  .btn_login {
    width: 160px;
    height: 40px;
    background: #339c9b;
    border-radius: 20px;
    font-size: 16px;
    color: #ffffff;
    border: none;
    cursor: pointer;
  }
  .login_wrap >>> .el-dialog--center .el-dialog__body {
    overflow: hidden;
  }
  .login_wrap >>> .pop_login {
    max-width: 550px;
    min-width: 368px;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 20px 40px 70px 0px rgba(12, 79, 71, 0.08);
  }
  .login_tabs /deep/ .el-tabs__content {
    overflow: inherit;
    width: 100%;
  }
  .login_tabs /deep/ .el-tabs__header {
    margin-bottom: 40px;
  }
  .login_tabs /deep/ .el-tabs__nav-scroll {
    width: 100%;
    height: 50px;
    background: #f7fafc;
    border-radius: 25px;
  }
  .login_tabs /deep/ .el-tabs__nav-wrap::after {
    z-index: -1;
  }
  .login_tabs /deep/.el-tabs__item {
    width: 225px;
    height: 50px;
    line-height: 50px;
    border-radius: 25px;
    text-align: center;
    color: #54657f;
    font-size: 18px;
  }
  .login_tabs /deep/.el-tabs__item.is-top.is-active {
    background: #339c9b;
    color: #ffffff;
  }
  .login_tabs /deep/ .el-tabs__active-bar {
    background-color: #339c9b;
    transition: none;
  }
  .accunt_form {
    position: relative;
  }
  .accunt_form /deep/ .el-form-item:not(:last-child) {
    margin-bottom: 40px;
  }

  .accunt_form /deep/ .el-input__inner {
    width: 100%;
    height: 50px;
    background: #ffffff;
    border-radius: 32px;
    border: 1px solid #d6dbe4;
    color: #54657f;
    font-size: 16px;
  }
  .accunt_form /deep/ .send_code .el-input__inner {
    width: 300px;
  }
  .accunt_form /deep/ .el-form-item {
    margin-bottom: 30px;
  }
  .password_inpt /deep/ .icon-yanjing {
    font-size: 16px;
    color: #92a2bc;
    position: absolute;
    width: 60px;
    right: 0;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
  }
  .password_inpt /deep/.el-input__icon.el-icon-view.el-input__clear {
    display: none;
  }
  .setTime,
  .btn,
  .btn_form_login {
    width: 100%;
    height: 50px;
    background: #339c9b;
    border-radius: 32px;
    font-size: 18px;
    color: #ffffff;
  }
  .btn_form_login {
    box-shadow: 0px 16px 32px 0px rgba(47, 160, 159, 0.23);
  }
  .setTime,
  .btn_send_code {
    width: 140px;
    flex: none;
  }
  .setTime {
    background: #7c969b;
    text-align: center;
    line-height: 50px;
  }
  .register {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 20px;
    text-align: center;
  }
  .register span {
    color: #339c9b;
    cursor: pointer;
  }
  .send_code_group /deep/ .el-form-item__content {
    display: flex;
    justify-content: space-between;
  }
  .accunt_form /deep/ .el-form-item__error {
    margin-left: 30px;
    margin-top: 4px;
  }
  .err_bg1 {
    position: absolute;
    width: 330px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #fb6476;
    text-align: center;
    top: -40px;
    left: 80px;
    z-index: 88;
  }
  .err_bg {
    position: absolute;
    width: 330px;
    height: 68px;
    line-height: 66px;
    font-size: 14px;
    color: #fb6476;
    text-align: center;
    background-image: url(../../../assets/img/log_err_bg.png);
    background-size: 330px 66px;
    background-repeat: no-repeat;
    top: -65px;
    left: 80px;
    z-index: 88;
  }
  .err_bg .icon-jinggao {
    font-size: 16px;
    color: #fb6476;
  }
  .pop_login >>> .el-dialog__headerbtn i.el-dialog__close.el-icon-close {
    font-size: 40px;
  }
  .login_wrap >>> .el-tabs__nav.is-top {
    width: auto;
  }
  .radio_user {
    margin-top: 40px;
  }
  .radio_user .el-radio-group {
    display: flex;
    justify-content: space-between;
  }

  .radio_user /deep/ .el-radio-button__inner,
  .radio_user .el-radio-button {
    width: 110px;
    height: 50px;
    background: #f7fafc;
    border-radius: 25px;
    border: 1px solid #f7fafc;
  }
  .radio_user /deep/ .el-radio-button__inner {
    font-size: 18px;
    color: #54657f;
    line-height: 1.4;
  }
  .radio_user /deep/ .el-radio-button.is-active .el-radio-button__inner {
    color: #fff;
    background: #339c9b;
  }
  .radio_user
    /deep/
    .el-radio-button__orig-radio:checked
    + .el-radio-button__inner {
    position: relative;
    box-shadow: none;
  }
  .dialog_width500 >>> .el-dialog__body {
    margin-top: 30px;
  }
  .btn_box_next {
    width: 210px;
    margin: 50px auto 30px;
  }
  .btn_next {
    width: 100%;
    height: 50px;
    border-radius: 32px;
    font-size: 18px;
    color: #ffffff;
    border: none;
    background: #339c9b;
    padding: 0;
    box-shadow: 0px 16px 32px 0px rgba(47, 160, 159, 0.23);
  }
  .btn_form_prev,
  .btn_form_log {
    width: 210px;
    height: 50px;
    border-radius: 32px;
    font-size: 18px;
    color: #ffffff;
    border: none;
    padding: 0;
  }
  .btn_form_log {
    background: #339c9b;
  }
  .btn_form_prev {
    background: #7c969b;
  }
  .btn_form_prev,
  .btn_form_log {
    box-shadow: 0px 16px 32px 0px rgba(47, 160, 159, 0.23);
  }
  .login_wrap >>> .el-dialog--center .el-dialog__body {
    padding-left: 40px;
    padding-right: 40px;
  }
  .login_wrap >>> .el-button + .el-button {
    margin-left: 30px;
  }
  .reg_m_b {
    margin-bottom: 50px;
  }
  @media screen and (max-width: 1664px) {
    .home_log {
      margin-top: 79px;
      height: 112px;
      box-shadow: 13px 26px 46px 0px rgb(12 79 71 / 10%);
      border-radius: 4px;
      padding: 33px 0 40px;
      width: 1056px;
    }
    .home_center_title {
      font-size: 16px;
      margin-bottom: 13px;
    }
    .home_center_cont {
      font-size: 12px;
    }
    .btn_login {
      width: 106px;
      height: 26px;
      line-height: 26px;
      border-radius: 13px;
      font-size: 12px;
    }
    .login_tabs /deep/ .el-tabs__nav-scroll {
      height: 33px;
      border-radius: 16px;
    }
    .login_tabs /deep/.el-tabs__item {
      width: 148px;
      height: 33px;
      line-height: 33px;
      border-radius: 16px;
      font-size: 12px;
      padding: 0 13px;
    }
    .login_wrap >>> .el-dialog__headerbtn {
      top: 13px;
    }
    .setTime,
    .btn,
    .btn_form_login,
    .accunt_form /deep/ .el-input__inner {
      height: 33px;
      border-radius: 21px;
      font-size: 12px;
    }
    .accunt_form /deep/ .send_code .el-input__inner {
      width: 198px;
    }
    .setTime,
    .btn,
    .btn_form_login {
      padding: 0;
    }
    .setTime,
    .btn_send_code {
      width: 92px;
    }
    .login_tabs >>> .el-tabs__header,
    .accunt_form >>> .el-form-item:not(:last-child) {
      margin-bottom: 26px;
    }
    .register {
      line-height: 17px;
      transform: scale(0.8);
      -webkit-transform: scale(0.8);
    }
    .accunt_form /deep/ .el-form-item {
      margin-bottom: 20px;
    }
    .password_inpt >>> .icon-yanjing {
      font-size: 10px;
      width: 40px;
      height: 33px;
      line-height: 33px;
    }
    .err_bg1 {
      position: absolute;
      width: 230px;
      height: 27px;
      line-height: 27px;
      font-size: 12px;
      top: -27px;
      left: 48px;
      z-index: 88;
    }
    .err_bg {
      width: 220px;
      height: 45px;
      line-height: 42px;
      font-size: 12px;
      background-size: 230px 44px;
      top: -45px;
      left: 48px;
    }
    .err_bg .icon-jinggao {
      font-size: 12px;
    }
    .radio_user {
      margin-top: 26px;
    }
    .radio_user >>> .el-radio-button__inner,
    .radio_user >>> .el-radio-button {
      width: 73px;
      height: 33px;
      border-radius: 17px;
    }
    .radio_user >>> .el-radio-button__inner {
      padding: 8px 13px;
    }
    .dialog_width500 >>> .el-dialog__body {
      margin-top: 20px;
    }
    .radio_user >>> .el-radio-button__inner {
      font-size: 12px;
      line-height: 1.2;
    }
    .btn_next {
      height: 33px;
      padding: 0;
      font-size: 12px;
      box-shadow: 0px 11px 21px 0px rgba(47, 160, 159, 0.23);
    }
    .btn_box_next {
      width: 125px;
      margin: 33px auto 20px;
    }
    .btn_form_prev,
    .btn_form_log {
      width: 139px;
      height: 33px;
      border-radius: 21px;
      font-size: 12px;
    }
    .btn_form_prev,
    .btn_form_log {
      box-shadow: 0px 11px 21px 0px rgba(47, 160, 159, 0.23);
    }
    .login_wrap >>> .el-dialog--center .el-dialog__body {
      padding-left: 26px;
      padding-right: 26px;
    }
    .login_wrap >>> .el-button + .el-button {
      margin-left: 20px;
    }
    .reg_m_b {
      margin-bottom: 28px;
    }
    .home_center_item {
      width: 218px;
    }
  }
</style>
