<template>
  <div class="home_wrap">
    <div class="home_container">
      <!-- 头部 -->
      <div class="home_top_head home_w">
        <div class="home_top_logo f_left">
          <img src="@/assets/img/logo.png" class="home_top_logoImg" title="商易融" />
        </div>
        <div class="home_top_right">
          <div class="home_lang f_right cur_point">
            <div class="header_lang" @click.stop="(lang = !lang), (showOut = false)">
              <div class="el-dropdown-link flex_center">
                {{langFont}}<i class="iconfont icon-jiantou cur_point"></i>
              </div>
              <ul v-if="lang" class="header_login_function_out_div about">
                <li @click="langFont = '中文'" class="cur_point">中文</li>
                <li @click="langFont = 'English'" class="cur_point">English</li>
              </ul>
            </div>
          </div>
          <div class="home_wachat f_right">
            <el-popover placement="bottom" width="210" trigger="click" popper-class="chat_pop" v-model="popChatForm">
              <el-form ref="weChatForm" :model="weChatForm" class="chat_form">
                <div class="close_chat_form" @click="popChatForm=false"><i class="close_chat_icon iconfont icon-yuyinguanbi"></i> </div>
                <el-form-item>
                  <el-input v-model="weChatForm.businesEmail" placeholder="企业邮箱"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-input v-model="weChatForm.businesName" placeholder="企业名称"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-input v-model="weChatForm.businesMassage" class="no_resize" placeholder="有什么需要我们帮助的？" type="textarea" maxRows="3" resize="false"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="onSubmitWechat" class="chat_send">发送</el-button>
                </el-form-item>
              </el-form>
              <el-button class="home_wachatBtn" slot="reference">联系我们</el-button>
            </el-popover>
          </div>

        </div>
      </div>

      <!-- 广告语 -->
      <div class="home_w">
        <div class="home_ad_cont">
          <div class="home_ad_cont_title">商易融</div>
          <div class="home_ad_cont_Des">一个专为供应商、中间商和买方提供创新的财务解决方案以释放全球供应链中的营运资金，改善现金流的线上管理平台。</div>
          <ul class="home_ad_cont_tip">
            <li class="no_margin_left">· 资信全覆盖</li>
            <li>· 便捷的全在线操作</li>
            <li>· 真正的信用评级模型</li>
            <li>· 全程风控服务</li>
          </ul>
          <a class="home_ad_cont_morebtn">了解更多</a>
        </div>
      </div>

      <!-- 登录 -->
      <Login />
    </div>

    <!-- 平台现金流标题 -->
    <div class="home_cash home_w">
      <div class="cash_img"><img src="@/assets/img/improve.png" /></div>
      <h1 class="home_cash_title text_center">商易融供应链管理平台全名支持提升企业现金流</h1>
    </div>
    <!-- 平台现金流五大特点 -->
    <div class="home_cash_progrem flex_center_wrap">
      <div class="cash_prog_item">
        <div class="cash_prog_img one">
          <img src="@/assets/img/home_cash1.png" />
        </div>
        <div class="cash_prog_title one">供应商解决方案</div>
        <div class="cash_prog_des one">通过3ACredit的全球企业数据库搜索目标公司。核实合作伙伴，寻求任何行业、地区、规模、性质、产品的目标市场及发展</div>
      </div>

      <div class="cash_prog_item cash_prog_item_m">
        <div class="cash_prog_img two">
          <img src="@/assets/img/home_cash2.png" />
        </div>
        <div class="cash_prog_title two">企业资信核实</div>
        <div class="cash_prog_des two">通过对目标企业360度观察，判断主体，全面了解股东结构、观察业务链、营业表现，时刻掌握负面信息</div>
      </div>

      <div class="cash_prog_item three">
        <div class="cash_prog_img three">
          <img src="@/assets/img/home_cash3.png" />
        </div>
        <div class="cash_prog_title three">寻找买家</div>
        <div class="cash_prog_des three">通过3ACredit的全球企业数据库搜索目标公司。核实合作伙伴，寻求任何行业、地区、规模、性质、产品的目标市场及发展</div>
      </div>

      <div class="cash_prog_item cash_last_m">
        <div class="cash_prog_img four">
          <img src="@/assets/img/home_cash4.png" />
        </div>
        <div class="cash_prog_title four">核心企业管理方案</div>
        <div class="cash_prog_des four">了解核实目标企业现状，判断目标公司短期信用评级，评估市场价值，比较行业对表企业，预估未来发展前景</div>
      </div>

      <div class="cash_prog_item">
        <div class="cash_prog_img fine">
          <img src="@/assets/img/home_cash5.png" />
        </div>
        <div class="cash_prog_title fine">企业预警监踪</div>
        <div class="cash_prog_des fine">通过对目标企业360度观察，判断主体，全面了解股东结构，观察业务链，营业表现，时刻掌握负面信息</div>
      </div>

    </div>
    <!-- 平台现金流总结 -->
    <div class="home_center home_w home_cash_bottom">
      <div class="home_cash_des_img">
        <i class="iconfont icon-Bookmark home_cash_ic"></i>
      </div>
      <ul class="home_cash_des_txt">
        <li>· 资信全覆盖</li>
        <li>· 便捷的全在线操作</li>
        <li>· 真正的信用评级模型</li>
        <li>· 全程风控服务</li>
      </ul>
    </div>

    <!-- 解决方案 -->
    <div class="home_solu">
      <div class="home_solu_wrap home_w">
        <div class="home_solu_top_img">
          <img src="@/assets/img/our_solution.png" />
        </div>
        <div class="home_solu_cont">
          <h2 class="home_solu_top_title">我们的解决方案</h2>
          <div class="home_solu_item one">
            <div class="home_solu_cont_title">中小企业融资难、融资贵</div>
            <div class="home_solu_cont_des">
              <p>· 先解决融资难。以中小企业应收账为抓手，实现线上确认、线上评估、线上放款、锁定回款的全流程标准化融资产品。 </p>
              <p>· 再解决融资贵。引入机构资金参与标准化的ABS产品的发行，为机构投资者提供专业的底层资产的分类、评级、标准化，逐步降低融资成本。</p>
            </div>
          </div>
          <div class="home_solu_item two">
            <div class="home_solu_cont_title">中小企业供应链条信用数据无效</div>
            <div class="home_solu_cont_des">
              <p>· 对不同付款人的应收款进行信用评级、分类融资、跟踪管理、区别对待。</p>
              <p>· 提供线上管理工具、让小企业主实时了解自己应收账款的风险状态。</p>
            </div>
          </div>
          <div class="home_solu_item three">
            <div class="home_solu_cont_title">中小企业供应链管理绩效差</div>
            <div class="home_solu_cont_des">
              <p>· 提供一整套商业信用管理服务，卖方信用评估，商账融资、跟踪、催收、实现全流程、数据化、线上、线下结合的商业信用管理服务、帮助企业提升供应链的竞争力。</p>
            </div>
          </div>
          <div class="home_solu_item four">
            <div class="home_solu_cont_title">为机构投资者提供高价、优质、标准化的底层资产</div>
            <div class="home_solu_cont_des">
              <p>· 提供大量标准化、高价、优质的应收类债权底层资产。</p>
              <p>· 搭建全维度的底层资产信息服务平台。</p>
              <p>· 提供专业化、全流程的ABS发行支持服务。</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 视频图文介绍 -->
    <About />

    <!-- 底部 -->
    <div class="home_footer">
      <div class="home_footer_wrap home_w">
        <div class="footer_top_img">
          <img src="@/assets/img/start_demon.png" /> -->
        </div>
        <div class="flex_center_between_box footer_top_cont">
          <div class="footer_title">
            <p>现在就开始演示！</p>
            <p>更全面的了解我们，并加入我们</p>
          </div>
          <div class="footer_btn">
            <router-link class="btn_footer first_btn" to="#">立即申请演示</router-link>
            <router-link class="btn_footer" to="/secure/register">立即注册</router-link>
          </div>
        </div>
        <div class="footer_bottom flex_center_between_box">
          <div class="footer_bottom_left flex_just_start_box">
            <dl class="foote_link">
              <dt>供应商</dt>
              <dd>
                <router-link to="#">我的工作台</router-link>
              </dd>
              <dd>
                <router-link to="#">我的发票</router-link>
              </dd>
              <dd>
                <router-link to="#">我的融资</router-link>
              </dd>
              <dd>
                <router-link to="#">我的还款</router-link>
              </dd>
              <dd>
                <router-link to="#">我的伙伴</router-link>
              </dd>
              <dd>
                <router-link to="#">我的信用</router-link>
              </dd>
              <dd>
                <router-link to="#">我的获客</router-link>
              </dd>
              <dd>
                <router-link to="#">我的服务</router-link>
              </dd>
              <dd>
                <router-link to="#">功能定义</router-link>
              </dd>
            </dl>
            <dl class="foote_link">
              <dt>核心企业</dt>
              <dd>
                <router-link to="#">我的工作台</router-link>
              </dd>
              <dd>
                <router-link to="#">我的发票</router-link>
              </dd>
              <dd>
                <router-link to="#">我的融资</router-link>
              </dd>
              <dd>
                <router-link to="#">我的供应商</router-link>
              </dd>
              <dd>
                <router-link to="#">我的客户</router-link>
              </dd>
              <dd>
                <router-link to="#">我的投资</router-link>
              </dd>
              <dd>
                <router-link to="#">我的获客</router-link>
              </dd>
              <dd>
                <router-link to="#">我的服务</router-link>
              </dd>
              <dd>
                <router-link to="#">功能定义</router-link>
              </dd>
            </dl>
            <dl class="foote_link">
              <dt>资方</dt>
              <dd>
                <router-link to="#">我的工作台</router-link>
              </dd>
              <dd>
                <router-link to="#">我的公海</router-link>
              </dd>
              <dd>
                <router-link to="#">我的私海</router-link>
              </dd>
              <dd>
                <router-link to="#">我的风险池</router-link>
              </dd>
              <dd>
                <router-link to="#">我的投资</router-link>
              </dd>
              <dd>
                <router-link to="#">我的推荐</router-link>
              </dd>
              <dd>
                <router-link to="#">我的服务</router-link>
              </dd>
              <dd>
                <router-link to="#">功能定义</router-link>
              </dd>
            </dl>
            <dl class="foote_link">
              <dt>服务商</dt>
              <dd>
                <router-link to="#">订单中心</router-link>
              </dd>
            </dl>
            <dl class="foote_link">
              <dt>个人中心</dt>
              <dd>
                <router-link to="#">账户管理</router-link>
              </dd>
              <dd>
                <router-link to="#">账户设置</router-link>
              </dd>
              <dd>
                <router-link to="#">我的评级</router-link>
              </dd>
              <dd>
                <router-link to="#">合同配置</router-link>
              </dd>
              <dd>
                <router-link to="#">我的通知</router-link>
              </dd>
            </dl>
          </div>
          <div class="footer_bottom_right">
            <div class="footer_bottom_right_title">想要了解更多？</div>
            <div class="footer_bottom_right_des">我们将通过电子邮件和您联系 </div>
            <div class="footer_bottom_right_inpt">
              <input class="footer_inpt" type="text" v-model="sendEmail" placeholder="请输入邮箱号" />
              <button class="btn_footer_send"><i class="iconfont icon-fasong ic_send"></i></button>
            </div>
          </div>
        </div>
      </div>
      <div class="footer_last flex_center">
        <div class="home_w flex_center">
          <div class="footer_last_cont flex_center">沪ICP备15022071号
            <span class="f_m_l">沪公网安备 31011302003405号<img src="@/assets/img/home_footer_lg.png" /></span>
          </div>
          <p class="footer_last_tip">增值电信业务经营许可证：沪B2-20180159 涉外调查许可证：国统涉外证字第1293号</p>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import About from './components/aboutSuppliers.vue'
import Login from './components/login.vue'
export default {
  components: { Login, About },
  data() {
    return {
      //联系我们表单
      weChatForm: {
        businesEmail: '',
        businesName: '',
        businesMassage: '',
      },
      popChatForm: false,//联系我们pop
      langFont: '中文',
      lang: false,
      showOut: false,
      sendEmail: ''
    }
  },
  created() {
    const self = this;
    document.documentElement.onclick = function () {
      self.showOut = false;
      self.lang = false;
    }
  },

  methods: {
    onSubmitWechat() {
      this.popChatForm = false
    }
  }
}
</script>
<style>
  .home_wrap {
    width: 100%;
    min-width: 1280px;
  }
  .home_bgcolor {
    background: #f7fafc;
  }
  .home_container {
    /* max-width: 1920px; */
    /* min-width: 1280px; */
    background: url('../../assets/img/home_bg.jpg') no-repeat;
    background-color: #f7fafc;
    background-size: 100%;
    background-position: center 0;
    margin: auto;
    height: 960px;
  }
  .home_top_head {
    height: 65px;
    margin: auto;
  }
  .home_w {
    width: 1600px;
    max-width: 1920px;
    min-width: 1240px;
    padding: 0 20px;
    margin: auto;
  }
  .f_left {
    float: left;
  }
  .f_right {
    float: right;
  }
  .home_top_logo {
    padding: 20px 0 0;
  }
  .home_top_logoImg {
    height: 20px;
  }
  .el-button--primary:focus,
  .el-button--primary:hover {
    background: #339c9b;
    border-color: #339c9b;
    color: #fff;
  }
  .home_wachatBtn {
    font-size: 16px;
    color: #ffffff;
    padding: 22px 0;
    line-height: 21px;
    border: none;
    background: transparent;
    cursor: pointer;
  }
  .home_lang {
    margin-left: 60px;
    margin-top: 22px;
  }
  .el-dropdown-menu__item:focus,
  .el-dropdown-menu__item:not(.is-disabled):hover {
    color: #339c9b;
  }
  /* .el-popover.el-popper{
      top: 40px !important;
      border:none;
      background-color: rgba(136, 155, 159, .89);
  } */
  .el-popover__reference-wrapper .el-button:focus,
  .el-popover__reference-wrapper .el-button:hover {
    background: transparent;
    color: #ffffff;
  }
  .chat_form {
    width: 182px;
    height: 217px;
  }
  .el-popover.el-popper .popper__arrow {
    display: none;
  }
  .chat_form {
    position: relative;
  }
  .chat_form .el-form-item {
    margin-bottom: 16px;
  }
  .close_chat_form {
    position: relative;
    height: 20px;
  }
  .close_chat_icon {
    position: absolute;
    right: -6px;
    top: -8px;
    font-size: 18px;
  }
  .icon-yuyinguanbi {
    color: #fff;
  }
  .chat_form .el-input__inner {
    height: 30px;
  }
  .no_resize .el-textarea__inner {
    resize: none;
  }
  .chat_send {
    width: 182px;
    height: 30px;
    background: #339c9b;
    border: 1px solid #339c9b;
    padding: 0;
  }
  .home_ad_cont_title {
    font-size: 40px;
    color: #ffffff;
    margin-bottom: 30px;
  }
  .home_ad_cont_Des {
    font-family: 'Microsoft YaHei Light';
    font-size: 30px;
    color: #ffffff;
    margin-bottom: 50px;
  }
  .home_ad_cont {
    width: 52.75%;
    line-height: 63px;
    margin-top: 145px;
  }
  .home_ad_cont_tip {
    font-size: 20px;
    font-weight: 300;
    color: #ffffff;
    margin-bottom: 110px;
  }
  .home_ad_cont_tip li {
    display: inline-block;
    margin-left: 20px;
    font-family: 'Microsoft YaHei Light';
  }
  .no_margin_left {
    margin-left: 0 !important;
  }
  .home_ad_cont_morebtn {
    display: inline-block;
    width: 180px;
    height: 40px;
    line-height: 40px;
    background: #339c9b;
    box-shadow: 0px 2px 20px 2px rgba(3, 60, 72, 0.98);
    border-radius: 20px;
    font-size: 16px;
    color: #fff;
    text-align: center;
  }
  .m_auto {
    margin: auto;
  }
  .text_center {
    text-align: center;
  }
  .home_center {
    margin-top: 120px;
    width: 83.3333%;
    height: 170px;
    background-color: #fff;
    box-shadow: 20px 40px 70px 0px rgba(12, 79, 71, 0.1);
    border-radius: 6px;
    padding: 50px 0 46px;
  }
  .home_cash {
    margin-top: 140px;
  }
  .cash_img {
    text-align: center;
  }
  .cash_img img {
    width: 348px;
    margin-bottom: 40px;
  }
  .home_cash_title {
    font-size: 36px;
    font-weight: 500;
    color: #162747;
  }
  .home_cash_progrem {
    padding: 100px 6% 120px;
  }
  .cash_prog_item {
    position: relative;
    width: 414px;
    height: 414px;
    background-color: rgba(51, 156, 155, 0.03);
    border-radius: 50%;
  }
  .cash_prog_item_m {
    margin-right: 221px;
    margin-left: 221px;
  }
  .cash_prog_img {
    display: inline-block;
    position: absolute;
    top: 30px;
    left: -20px;
  }
  .cash_prog_img.one {
    width: 238px;
  }
  .cash_prog_img.two {
    width: 282px;
  }
  .cash_prog_img.three {
    width: 248px;
  }
  .cash_prog_img.four {
    width: 278px;
  }
  .cash_prog_img.fine {
    width: 248px;
  }
  .cash_prog_title {
    position: absolute;
    top: 145px;
    left: 205px;
    display: inline-block;
    font-size: 22px;
    font-weight: 500;
  }
  .cash_prog_title.one {
    left: 165px;
  }
  .cash_prog_title.three {
    left: 175px;
  }
  .cash_prog_title.four {
    left: 202px;
  }
  .cash_prog_title.fine {
    left: 172px;
  }
  .cash_prog_des {
    position: absolute;
    bottom: 100px;
    left: 65px;
    font-size: 16px;
    color: #54657f;
    width: 290px;
    line-height: 30px;
  }
  .cash_last_m {
    margin-right: 221px;
  }
  .cash_prog_des.one {
    bottom: 67px;
  }
  .cash_prog_des.two {
    bottom: 100px;
  }
  .cash_prog_des.three {
    bottom: 75px;
  }
  .cash_prog_des.four {
    bottom: 110px;
  }
  .cash_prog_des.fine {
    bottom: 97px;
  }
  .home_cash_des_img {
    display: inline-block;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    border-radius: 50%;
    background: #339c9b;
    box-shadow: 8px 22px 24px 0px rgba(20, 216, 214, 0.15);
  }
  .home_cash_bottom {
    position: relative;
    padding: 45px 80px;
    z-index: 1;
    display: flex;
  }
  .home_cash_ic {
    font-size: 35px;
    color: #fff;
  }
  .home_cash_des_txt {
    display: flex;
    align-items: center;
    font-size: 22px;
    color: #162747;
    margin-left: 240px;
  }
  .home_cash_des_txt li {
    display: inline-block;
    margin-left: 30px;
  }
  .home_solu {
    margin-top: -80px;
    padding-top: 284px;
    background-color: #fff;
  }
  .home_solu_top_img {
    margin-bottom: 45px;
  }
  .home_solu_top_img img {
    width: 137px;
  }
  .home_solu_cont {
    position: relative;
    background-image: url('../../assets/img/home_sol.png');
    background-repeat: no-repeat;
    background-size: 1207px 927px;
    padding-bottom: 120px;
  }
  .home_solu_top_title {
    font-size: 36px;
    color: #162747;
  }
  .home_solu_item {
    margin-bottom: 140px;
  }
  .home_solu_item.one {
    margin-left: 870px;
  }
  .home_solu_item.two {
    margin-left: 1070px;
    top: 140px;
  }
  .home_solu_item.three {
    margin-left: 870px;
  }
  .home_solu_item.four {
    margin-left: 650px;
    margin-bottom: 0;
  }
  .home_solu_cont_title {
    font-size: 22px;
    color: #339c9b;
    margin-bottom: 28px;
  }
  .home_solu_cont_des {
    width: 530px;
    font-size: 16px;
    color: #54657f;
    line-height: 30px;
  }
  .header_lang {
    position: relative;
    line-height: 21px;
    font-size: 16px;
    color: #ffffff;
  }
  .header_menu_item .router-link-active::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    width: 80px;
    height: 4px;
    background-color: #339c9b;
    border-radius: 2px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .header_login_function_out {
    position: relative;
  }
  .about {
    right: -20px !important;
  }
  .header_login_function_out_div {
    position: absolute;
    top: 30px;
    right: -45px;
    width: 116px;
    /* height: 133px; */
    padding: 29px 8px 25px 8px;
    background-image: url('../../assets/img/system/headericon1.png');
    background-size: 100% 100%;
    color: #162747;
  }
  .header_login_function_out_div li:nth-child(1) {
    margin-bottom: 10px;
  }
  .header_login_function_out_div li {
    padding: 0;
    width: 100%;
    height: 34px;
    line-height: 34px;
    font-size: 14px;
    text-align: center;
  }
  .header_login_function_out_div li:hover {
    background-color: rgba(51, 156, 155, 0.16);
    color: #339c9b;
  }
  .icon-fanhui {
    color: #339c9b;
    font-size: 24px;
    margin-right: 10px;
  }
  .home_footer {
    background: #1e2634;
  }
  .home_footer_wrap {
    padding: 140px 0 80px;
  }
  .footer_top_img {
    margin-bottom: 55px;
  }
  .footer_top_img img {
    width: 223px;
  }
  .footer_title {
    font-size: 40px;
    color: #ffffff;
    line-height: 70px;
  }
  .btn_footer {
    display: inline-block;
    width: 300px;
    height: 60px;
    line-height: 60px;
    background: #339c9b;
    border-radius: 30px;
    text-align: center;
    color: #fff;
    font-size: 20px;
  }
  .first_btn {
    margin-right: 80px;
  }
  .footer_top_cont {
    padding-bottom: 140px;
    border-bottom: 1px solid #3c4554;
    margin-bottom: 110px;
  }
  .foote_link {
    font-family: 'Microsoft YaHei Light';
    margin-right: 142px;
    font-size: 16px;
  }
  .foote_link dt {
    font-size: 16px;
    color: #7c969b;
    margin-bottom: 40px;
  }
  .foote_link dd {
    margin-bottom: 22px;
  }
  .foote_link dd a {
    font-family: 'Microsoft YaHei Light';
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 22px;
  }
  .footer_bottom_left {
    width: 1080px;
  }
  .footer_bottom_right {
    width: 520px;
  }
  .footer_bottom_right_title {
    font-size: 18px;
    color: #7c969b;
    margin-bottom: 30px;
  }
  .footer_bottom_right_des {
    font-family: 'Microsoft YaHei Light';
    font-size: 18px;
    color: #ffffff;
    margin-bottom: 30px;
  }
  .footer_bottom_right_inpt {
    position: relative;
  }
  .footer_inpt {
    width: 100%;
    height: 80px;
    font-size: 16px;
    background: rgba(191, 207, 219, 0.09);
    border-radius: 40px;
    color: #fff;
    border: none;
    padding: 0 84px 0 30px;
  }
  .footer_inpt:focus-visible,
  .footer_inpt:active {
    outline: none;
  }
  .footer_inpt::placeholder {
    color: #7c969b;
  }
  .ic_send {
    font-size: 35px;
    color: #fff;
  }
  .btn_footer_send {
    position: absolute;
    right: 12px;
    top: 10px;
    width: 60px;
    height: 60px;
    background: #339c9b;
    border: none;
    border-radius: 50%;
    cursor: pointer;
  }
  .footer_last {
    font-size: 12px;
    color: #ffffff;
    height: 60px;
    background: rgba(255, 255, 255, 0.1);
  }
  .footer_last_cont {
    font-family: 'Microsoft YaHei Light';
    height: 60px;
  }
  .f_m_l {
    margin-left: 20px;
    font-family: 'Microsoft YaHei Light';
  }
  .f_m_l img {
    float: right;
    width: 14px;
    margin-top: 2px;
    margin-left: 6px;
  }
  .footer_last_tip {
    margin-left: 6.25%;
    font-family: 'Microsoft YaHei Light';
  }
  .flex_just_start_box {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    font-family: 'Microsoft YaHei Light';
  }
  @media screen and (max-width: 1664px) {
    .home_w {
      width: 1056px;
      padding: 0 13px;
    }
    .home_container {
      height: 634px;
    }
    .home_top_head {
      height: 43px;
    }
    .home_top_logo {
      padding: 13px 0 0;
    }
    .home_top_logoImg {
      height: 13px;
    }
    .home_wachatBtn {
      padding: 16px 0 0;
    }
    .home_lang {
      margin-left: 40px;
      margin-top: 15px;
    }
    /* .el-popover.el-popper {
    top: 26px !important;
  } */
    .el-popover.el-popper,
    .home_wachatBtn,
    .header_lang {
      -webkit-transform: scale(0.8);
      transform: scale(0.8);
    }
    .home_ad_cont {
      line-height: 42px;
      margin-top: 96px;
    }
    .home_ad_cont_title {
      font-size: 26px;
      margin-bottom: 20px;
    }
    .home_ad_cont_Des {
      font-size: 20px;
      margin-bottom: 33px;
    }
    .home_ad_cont_tip li {
      margin-left: 13px;
    }
    .home_ad_cont_tip {
      font-size: 13px;
      margin-bottom: 73px;
    }
    .home_ad_cont_morebtn {
      font-size: 16px;
      -webkit-transform: scale(0.66, 0.66);
      -webkit-text-size-adjust: none;
      transform-origin: 0 0;
    }
    .home_cash {
      margin-top: 92px;
    }
    .cash_img img {
      width: 230px;
      margin-bottom: 26px;
    }
    .home_cash_title {
      font-size: 24px;
      font-weight: 400;
    }
    .home_cash_progrem {
      padding: 66px 6% 79px;
    }
    .cash_prog_item {
      width: 273px;
      height: 273px;
    }
    .cash_prog_item_m {
      margin-right: 146px;
      margin-left: 146px;
    }
    .cash_prog_img.one {
      width: 157px;
    }
    .cash_prog_title.one {
      left: 109px;
    }
    .cash_prog_img {
      top: 20px;
      left: -13px;
    }
    .cash_prog_des.one {
      bottom: 44px;
    }
    .cash_prog_title {
      top: 96px;
      left: 135px;
      font-size: 15px;
      font-weight: 400;
    }
    .cash_prog_des {
      bottom: 66px;
      left: 43px;
      font-size: 12px;
      width: 191px;
      line-height: 20px;
    }
    .cash_prog_img.two {
      width: 186px;
    }
    .cash_prog_des.two {
      bottom: 66px;
    }
    .cash_prog_img.three {
      width: 164px;
    }
    .cash_prog_des.three {
      bottom: 50px;
    }
    .cash_prog_title.three {
      left: 116px;
    }
    .cash_prog_img.four {
      width: 183px;
    }
    .cash_prog_title.four {
      left: 133px;
    }
    .cash_prog_des.four {
      bottom: 45px;
    }
    .cash_prog_img.fine {
      width: 164px;
    }
    .cash_prog_title.fine {
      left: 114px;
    }
    .cash_prog_des.fine {
      bottom: 60px;
    }
    .home_cash_bottom {
      margin-top: 79px;
      box-shadow: 13px 26px 46px 0px rgb(12 79 71 / 10%);
      height: 112px;
      width: 1056px;
      border-radius: 4px;
      padding: 30px 53px;
    }
    .home_cash_des_txt {
      margin-left: 158px;
      font-size: 15px;
    }
    .home_cash_des_img {
      width: 53px;
      height: 53px;
      line-height: 53px;
      box-shadow: 5px 15px 16px 0px rgb(20 216 214 / 15%);
    }
    .home_cash_des_txt li {
      margin-left: 20px;
    }
    .home_solu {
      margin-top: -53px;
      padding-top: 187px;
    }
    .home_solu_top_img {
      margin-bottom: 30 px;
    }
    .home_solu_top_img img {
      width: 90px;
    }
    .home_solu_top_title {
      font-size: 24px;
    }
    .home_solu_cont {
      background-size: 797px 612px;
      padding-bottom: 79px;
    }
    .home_solu_cont_title {
      font-size: 15px;
      margin-bottom: 18px;
    }
    .home_solu_item.one {
      margin-left: 574px;
    }
    .home_solu_item {
      margin-bottom: 90px;
    }
    .home_solu_cont_des {
      width: 394px;
      font-size: 12px;
      line-height: 20px;
    }
    .home_solu_item.two {
      margin-left: 706px;
      top: 92px;
    }
    .home_solu_item.three {
      margin-left: 574px;
    }
    .home_solu_item.four {
      margin-left: 429px;
    }
    .home_footer_wrap {
      padding: 92px 0 53px;
    }
    .footer_top_img {
      margin-bottom: 36px;
    }
    .footer_top_img img {
      width: 147px;
    }
    .footer_title {
      font-size: 26px;
      line-height: 46px;
    }
    .btn_footer {
      width: 198px;
      height: 40px;
      line-height: 40px;
      font-size: 13px;
    }
    .first_btn {
      margin-right: 53px;
    }
    .footer_top_cont {
      padding-bottom: 92px;
      margin-bottom: 73px;
    }
    .footer_bottom_left {
      width: 713px;
    }
    .foote_link dt {
      font-size: 12px;
      margin-bottom: 26px;
    }
    .foote_link dd {
      margin-bottom: 15px;
    }
    .foote_link dd a {
      font-size: 12px;
      margin-bottom: 15px;
    }
    .foote_link {
      /* margin-right: 120px; */
      margin-right: 87px;
    }
    .footer_bottom_right {
      width: 343px;
    }
    .footer_bottom_right_title {
      font-size: 12px;
      margin-bottom: 20px;
    }
    .footer_bottom_right_des {
      font-size: 12px;
      margin-bottom: 20px;
    }
    .footer_inpt {
      height: 53px;
      font-size: 12px;
      border-radius: 26px;
      padding: 0 55px 0 20px;
    }
    .btn_footer_send {
      right: 8px;
      top: 7px;
      width: 40px;
      height: 40px;
    }
    .ic_send {
      font-size: 23px;
    }
    .footer_last {
      height: 40px;
    }
    .footer_last_cont {
      height: 40px;
    }
    .footer_last_cont,
    .footer_last_tip {
      -webkit-transform: scale(0.88);
      transform: scale(0.88);
    }
    .err_bg {
      width: 185px;
      height: 45px;
      line-height: 38px;
      font-size: 12px;
      background-size: 185px 44px;
      top: 12px;
      left: 20%;
    }

    .err_bg .icon-jinggao {
      font-size: 12px;
    }
    .header_login_function_out_div {
      top: 20px;
      right: -30px;
      width: 77px;
      /* height: 88px; */
      padding: 19px 5px 20px 5px;
      font-size: 12px;
    }
  }
</style>